<script lang="ts" setup>
import { useStore } from '@nanostores/vue'
import { cartFormatted as cartFormattedStore } from '../services/cart'

const cartFormatted = useStore(cartFormattedStore)
</script>

<template>
  <div
    v-if="cartFormatted?.hasDiscounts"
    class="py-1 px-2 font-semibold leading-normal bg-lime-600 text-white w-full line-clamp-1"
  >
    Você tem
    <span class="whitespace-nowrap">{{ cartFormatted?.discountsTotal }}</span>
    de crédito em compras
  </div>
</template>
